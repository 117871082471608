import React, { FunctionComponent } from 'react';
import { connect, useSelector } from 'react-redux';
import { CampaignPlacementType, ITacoRecommendation } from 'scripts/api/targeting/targeting.interfaces';
import { Feature } from 'scripts/ui/feature/feature';
import { trackFeatures } from 'scripts/util/constants/track-features';
import { TacoPromoCta } from 'scripts/ui/taco-promos/taco-promo-cta';
import { postTacoTrackEvent } from 'scripts/thunks/taco-service-thunks';
import styled from 'styled-components';
import { fromTheme } from 'scripts/styles/themes/themes.utils';
import { container } from 'scripts/styles/utilities';
import { mobileOnly } from 'scripts/styles/breakpoints';
import { $Section } from 'scripts/styles/primitives/section';
import { useTacoDirect } from 'scripts/hooks/use-taco-direct/use-taco-direct';
import { useTaco } from 'scripts/hooks/use-taco/use-taco';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { shouldOpenPromoInSameTab } from 'scripts/util/targeting/targeting';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { getConfig } from 'scripts/util/config/config';
import { getGroupNumber } from 'scripts/util/plans/plans';
import { ICoverageSection } from 'scripts/features/modals/id-cards/id-cards.interfaces';

export interface ITacoArcadeDashboardBannerStateToProps {
  isTacoDirectBannerOn: boolean;
}

export interface ITacoArcadeDashboardBannerDispatchToProps {
  postTacoTrackEvent: () => Promise<void>;
}

export interface ITacoArcadeDashboardBannerProps
  extends ITacoArcadeDashboardBannerStateToProps,
    ITacoArcadeDashboardBannerDispatchToProps {}

// NOTE: the key names below aren't necessarily a good indicator of the actual colors they represent,
// because we assigned our own meaning to values that already existed within campaigns (ARC-9965)
const mapCampaignsThemeToClassName = {
  rallyBlueDark: 'bg-blue-dk',
  rallyBlueLight: 'bg-blue',
  rallyOrange: 'bg-yellow',
  rallyRedDark: 'bg-red',
  zipongoGreen: 'bg-green',
};

export const RawTacoArcadeDashboardBanner: FunctionComponent<ITacoArcadeDashboardBannerProps> = props => {
  const { postTacoTrackEvent, isTacoDirectBannerOn } = props;
  const { tacos: targetingTacos } = useTaco([CampaignPlacementType.ArcadeDashboardBanner], isTacoDirectBannerOn);
  const { tacos: directTacos } = useTacoDirect(
    [CampaignPlacementType.ArcadeDashboardBanner],
    isTacoDirectBannerOn,
    false,
  );
  const tacos = isTacoDirectBannerOn ? directTacos : targetingTacos;
  const bannerTaco: ITacoRecommendation | null = tacos.length ? tacos[0] : null;
  const currentProfile = useSelector(currentUser.selectProfile);
  const userGroupNumbers = currentProfile.planCoverages.map(planCoverage =>
    getGroupNumber(planCoverage as ICoverageSection, currentProfile),
  );
  const suppressedGroups: string[] = getConfig().ARCADE_WEB_GROUP_IDS_SUPPRESS.split(',');
  const hideBanner =
    userGroupNumbers && userGroupNumbers.length > 0
      ? userGroupNumbers.some(userGroupNumber => suppressedGroups.includes(userGroupNumber))
      : false;

  if (!bannerTaco || hideBanner) {
    return null;
  }

  const { placement } = bannerTaco;
  const bgColorClassName = mapCampaignsThemeToClassName[placement.theme] || mapCampaignsThemeToClassName.rallyBlueDark;

  return (
    <Feature featureId={trackFeatures.arcadeDashboardBanner}>
      <$Section as="section" role="region" aria-labelledby="banner-header" aria-describedby="banner-description">
        <$ArcadeDashboardBanner
          data-ui-section="arcade-dashboard-banner"
          data-testid="arcade-dashboard-banner"
          className={bgColorClassName}
        >
          <$BannerContainer>
            <$BannerLeft>
              <$BannerLogo>
                <$BannerLogoImg src={placement.imageUrl} alt="" />
              </$BannerLogo>
              <$BannerText>
                <$BannerHeadline id="banner-header">{placement.headline}</$BannerHeadline>
                <$BannerDescription id="banner-description">{placement.body}</$BannerDescription>
              </$BannerText>
            </$BannerLeft>
            <$BannerRight>
              <$BannerLink>
                <TacoPromoCta
                  recommendation={bannerTaco}
                  postTacoTrackEvent={postTacoTrackEvent}
                  className="rds-primary-button"
                  openNewTab={!shouldOpenPromoInSameTab(bannerTaco?.placement?.cta?.ctaValue)}
                />
              </$BannerLink>
            </$BannerRight>
          </$BannerContainer>
        </$ArcadeDashboardBanner>
      </$Section>
    </Feature>
  );
};

const $ArcadeDashboardBanner = styled.div`
  border-bottom: 6px solid ${fromTheme('clrNeutral80')};
  padding: ${fromTheme('spacing16')} 0;
  ${mobileOnly`
    min-height: 90px;
  `}

  &.bg-red {
    background-color: ${fromTheme('clrWarningLight')};
  }

  &.bg-yellow {
    background-color: ${fromTheme('clrOrangeLight')};
  }

  &.bg-green {
    background-color: ${fromTheme('clrBannerGreen')};
  }

  &.bg-blue {
    background-color: ${fromTheme('clrOlympicBlueLight')};
  }

  &.bg-blue-dk {
    background-color: ${fromTheme('clrBannerBlue')};
  }
`;

const $BannerContainer = styled.div`
  ${container}
  display: flex;
  flex-direction: row;
  ${mobileOnly`
    align-items: center;
    flex-direction: column;
  `}
`;

const $BannerLeft = styled.div`
  display: flex;
  flex-direction: row;
  ${mobileOnly`
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    text-align: center;
  `}
`;

const $BannerLogo = styled.div`
  box-sizing: content-box;
  ${mobileOnly`
    height: ${fromTheme('spacing48')};
    padding-bottom: ${fromTheme('spacing8')};
  `}
`;

const $BannerLogoImg = styled.img`
  height: ${fromTheme('spacing48')};
  width: ${fromTheme('spacing48')};
`;

const $BannerText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${fromTheme('fs16')};
  font-weight: ${fromTheme('fwMedium')};
  justify-content: center;
  margin-left: ${fromTheme('spacing16')};
  width: 100%;
  ${mobileOnly`
    margin: 0 ${fromTheme('spacing16')};
    width: calc(100% - 40px);
  `}
`;

const $BannerHeadline = styled.p`
  font-size: 18px;
  font-weight: ${fromTheme('fwBold')};
  &&&& {
    margin-bottom: 0px;
    ${mobileOnly`
      font-size: ${fromTheme('fs16')};
      line-height: ${fromTheme('lh20')};
      margin-bottom: 4px;
    `}
  }
`;

const $BannerDescription = styled.p`
  font-size: 18px;
  ${mobileOnly`
    font-size: ${fromTheme('fs16')};
    line-height: ${fromTheme('lh20')};
  `}
`;

const $BannerRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: ${fromTheme('spacing24')};
  min-width: fit-content;
  ${mobileOnly`
    margin: ${fromTheme('spacing16')} auto 0;
  `}
`;

const $BannerLink = styled.div`
  &&& {
    a {
      font-size: ${fromTheme('fs16')};
      font-weight: ${fromTheme('fwSemiBold')};
      margin-left: 40px;
      padding: 12px 10px;
      text-transform: capitalize;
      .icon-external-link {
        fill: ${fromTheme('clrWhite')};
      }
    }
    ${mobileOnly`
      a {
          margin-left: 0;
        }
    `}
  }
`;

const mapStateToProps = (state: IReduxState): ITacoArcadeDashboardBannerStateToProps => ({
  isTacoDirectBannerOn: selectFeatureFlags(state).ARCADE_FEATURES_TACO_DIRECT_BANNER_ON,
});

export const TacoArcadeDashboardBanner = connect(mapStateToProps, { postTacoTrackEvent })(RawTacoArcadeDashboardBanner);
