import { LineOfBusiness, MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import CONFIG from 'scripts/util/constants/config';
import { ArcadeAuthType, IConfig, ArcadeEnvironment } from '../constants/environment.interfaces';
import { IPopulation } from '../population/population.interfaces';
import { getResource } from '../resource/resource';
import { IAlternativeUrls, IBaseUrls, ICeClaimsBaseUrls } from './uri.interfaces';
import { ARCADE_WEB_INTERNAL_REDIRECT } from './uri.constants';
import { LastIndexedCoverageType, PartnerId } from 'scripts/api/connect/connect-service.interfaces';
import { constructParams } from './construct-params';
import { parse } from './parse';
import { RallyCaller } from 'scripts/util/constants/rally-caller.constants';
import { LocationDescriptor, LocationDescriptorObject } from 'history';
import { PagesDataByPath, IPageData, PagesData, PagesDataByPathAsList } from 'scripts/config/pages-data';
import FEATURE_FLAGS from 'scripts/util/constants/feature-flag';
import { splitConfig } from 'scripts/util/config/config-utils';
import { IResource } from 'scripts/util/resource/resource.interfaces';

function readAlternativeUrls(config: IConfig): IAlternativeUrls[] {
  return splitConfig(config.ARCADE_WEB_ALTERNATIVE_BASE_URLS, ';').map((urlPair: string): IAlternativeUrls => {
    const urls = urlPair.split(',');
    return {
      ARCADE_WEB_BASE_URL: urls[0].trim(),
      ARCADE_WEB_BASE_API_URL: urls[1].trim(),
    };
  });
}

function constructParamsNoEncoding(queryParams: object): string {
  return constructParams(queryParams, false);
}

function sanitizeLink(link: string): string {
  return link?.replace('http://', '').replace('https://', '').replace('www.', '');
}

export function constructUriWithoutVersion(uri: string, api: string, method: string, queryParams?: object): string {
  return constructUri(uri.concat('[version]'), api, '', method, {}, queryParams);
}

export function constructUri(
  uri: string,
  api: string,
  version: string,
  method: string,
  addUriParams?: object,
  addQueryParams?: object,
  queryParameterBuilder: (queryParams: object) => string = constructParams,
): string {
  for (const key in addUriParams) {
    if (Object.prototype.hasOwnProperty.call(addUriParams, key)) {
      addUriParams[key] = encodeURIComponent(addUriParams[key]);
    }
  }

  const params = {
    api,
    version,
    method,
    ...addUriParams,
  };

  if ((uri.match(/\[/g) || []).length === Object.keys(params).length && arguments.length >= 4) {
    for (const param in params) {
      if (Object.prototype.hasOwnProperty.call(params, param)) {
        uri = uri.replace('[' + param + ']', params[param]);
      }
    }
    return (uri += addQueryParams ? queryParameterBuilder(addQueryParams) : '');
  } else {
    throw new Error('Not enough parameters supplied to construct uri.');
  }
}

export function getBaseUrls(config: IConfig = CONFIG): IBaseUrls {
  const locationUri = parse(window.location.href).host;
  let webUrl;
  let apiUrl;
  if (locationUri === parse(config.ARCADE_WEB_BASE_URL).host) {
    webUrl = config.ARCADE_WEB_BASE_URL;
    apiUrl = config.ARCADE_WEB_BASE_API_URL;
  } else if (config.ARCADE_WEB_ALTERNATIVE_BASE_URLS) {
    for (const ALTERNATIVE_URL of readAlternativeUrls(config)) {
      if (locationUri === parse(ALTERNATIVE_URL.ARCADE_WEB_BASE_URL).host) {
        webUrl = ALTERNATIVE_URL.ARCADE_WEB_BASE_URL;
        apiUrl = ALTERNATIVE_URL.ARCADE_WEB_BASE_API_URL;
        break;
      }
    }
  }
  return { api: apiUrl, web: webUrl };
}

export function getCeClaimsBaseUrl(config: IConfig = CONFIG): ICeClaimsBaseUrls {
  return { api: config.ARCADE_WEB_CE_CLAIMS_AGGREGATE_BASE_URL };
}

const baseUrls = getBaseUrls(CONFIG);
const baseApiUri = (baseUrls.api || '') + '/rest/[api]/v[version]/[method]';
const baseAuthUri = baseApiUri + '/u/[rallyId]';
const pythiaUri = baseUrls.api + '/rest/[api]/v[version]/[method]';

const baseUrlsV5 = getCeClaimsBaseUrl(CONFIG);
const baseApiUriV5 = (baseUrlsV5.api || '') + '/[api]/v[version]/[method]';
const baseAuthUriV5 = baseApiUriV5 + '/u/[rallyId]';

export const activateUris = {
  stepsV7: (rallyId: string) => {
    const stepsUri = baseAuthUri;
    return constructUri(
      stepsUri,
      'edge-dashboard/activate-steps',
      '7',
      'stepplans',
      { rallyId },
      { allowCreation: 'false' },
    );
  },
  stepsPostStep: (rallyId: string, planKey: string, planVersion: number, stepId: string) => {
    const stepsUri = baseAuthUri + '/plan/[planKey]/planVersion/[planVersion]/step/[stepId]';
    return constructUri(
      stepsUri,
      'edge-dashboard/activate-steps',
      '7',
      'stepplans',
      { rallyId, planKey, planVersion, stepId },
      { allowCreation: 'false' },
    );
  },
  stepsPostPlanV7: (rallyId: string, planKey: string, planVersion: number) => {
    const stepsUri = baseAuthUri + '/plan/[planKey]/planVersion/[planVersion]';
    return constructUri(
      stepsUri,
      'edge-dashboard/activate-steps',
      '7',
      'stepplans',
      { rallyId, planKey, planVersion },
      { allowCreation: 'false' },
    );
  },
  stepsV9: () => {
    return constructUri(baseApiUri, 'edge-dashboard/activate-steps', '9', 'stepplans');
  },
  stepsPostStepV9: () => {
    return constructUri(baseApiUri, 'edge-dashboard/activate-steps', '9', 'stepplans');
  },
  stepsPostPlanV9: (flowKey: string, flowVersion: number) => {
    const stepsUri = baseApiUri + '/flow/[flowKey]/flowVersion/[flowVersion]';
    return constructUri(stepsUri, 'edge-dashboard/activate-steps', '9', 'stepplans', { flowKey, flowVersion });
  },
  stepsPostSwitch: (rallyId: string, planKey: string, planVersion: number) => {
    const stepsUri = baseAuthUri + '/plan/[planKey]/planVersion/[planVersion]';
    return constructUri(
      stepsUri,
      'edge-dashboard/activate-steps',
      '7',
      'dhp',
      { rallyId, planKey, planVersion },
      { allowCreation: 'false' },
    );
  },
  stepsSwitch: (rallyId: string) => {
    const stepsUri = baseAuthUri;
    return constructUri(stepsUri, 'edge-dashboard/activate-steps', '7', 'dhp', { rallyId });
  },
  healthTopics: () => constructUri(baseApiUri, 'edge-dashboard/preferences', '1', 'informational-preferences'),
  onboarding: (queryParams?: object) =>
    constructUri(
      baseApiUri,
      'edge-dashboard/activate-eligibility',
      '1',
      'onboarding/eligibility',
      undefined,
      queryParams,
    ),
};

export const advantageUris = {
  productAuthList: (config: IConfig) => {
    return `${config.ARCADE_WEB_RALLY_ADVANTAGE_EDGE_URL}/rest/advantage/v1/productauthlist`;
  },
  profile: (config: IConfig) => {
    return `${config.ARCADE_WEB_RALLY_ADVANTAGE_EDGE_URL}/rest/advantage/profile/v2/user`;
  },
  uiConfig: (config: IConfig, locale: string) => {
    return `${config.ARCADE_WEB_RALLY_ADVANTAGE_EDGE_URL}/rest/advantage/v2/chimera/uiconfig?locale=${locale}`;
  },
};

export const cardConversionUris = {
  idCardAccessibility: () => {
    return constructUri(baseApiUri, 'edge-dashboard/card-conversion', '1', 'translate');
  },
};

export const claimUris = {
  alerts: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'alerts', { rallyId }, queryParams);
  },
  healthcareSorted: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'sorted/healthcare', { rallyId }, queryParams);
  },
  manage: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '2', 'manage', { rallyId });
  },
  getUpdatedRallyPayData: (claimKey: string) => {
    const updateRallyPayDataUri = baseApiUri + '/c/[claimKey]';
    return constructUri(updateRallyPayDataUri, 'edge-dashboard/claims', '3', 'detail/rallypay', { claimKey });
  },
  rallyPayClaimDetailsV4: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '4', 'detail/rallypay', { rallyId }, queryParams);
  },
  totals: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'totals', { rallyId }, queryParams);
  },
};

export const documentUris = {
  claimLetterPdf: (rallyId: string, documentId: string, queryParams: object) => {
    const claimLetterUriV5 = baseAuthUriV5 + '/id/[documentId]';
    if (FEATURE_FLAGS.ARCADE_FEATURES_USE_CE_CLAIMS_AGGREGATE_API) {
      return constructUri(
        claimLetterUriV5,
        'ce-claims-aggregate-api/rest/documents',
        '1',
        'claims/letter',
        { rallyId, documentId },
        queryParams,
      );
    }
  },
  claimLettersList: (rallyId: string) => {
    if (FEATURE_FLAGS.ARCADE_FEATURES_USE_CE_CLAIMS_AGGREGATE_API) {
      return constructUri(baseAuthUriV5, 'ce-claims-aggregate-api/rest/documents', '1', 'claims/letter', { rallyId });
    }
  },
  financialClaimsForm: (rallyId: string, documentType: string, documentKey: string, queryParams: object) => {
    const claimDocumentUriV5 = baseAuthUriV5 + '/type/[documentType]/key/[documentKey]';
    if (FEATURE_FLAGS.ARCADE_FEATURES_USE_CE_CLAIMS_AGGREGATE_API) {
      return constructUri(
        claimDocumentUriV5,
        'ce-claims-aggregate-api/rest/documents',
        '1',
        'claims/financial',
        { rallyId, documentType, documentKey },
        queryParams,
      );
    }
  },
  healthcareClaimsForm: (rallyId: string, documentType: string, documentKey: string, queryParams: object) => {
    const claimDocumentUriV5 = baseAuthUriV5 + '/type/[documentType]/key/[documentKey]';
    if (FEATURE_FLAGS.ARCADE_FEATURES_USE_CE_CLAIMS_AGGREGATE_API) {
      return constructUri(
        claimDocumentUriV5,
        'ce-claims-aggregate-api/rest/documents',
        '1',
        'claims/healthcare',
        { rallyId, documentType, documentKey },
        queryParams,
      );
    }
  },
  eobs: (rallyId: string, queryParams?: object) => {
    if (FEATURE_FLAGS.ARCADE_FEATURES_USE_CE_CLAIMS_AGGREGATE_API) {
      return constructUri(
        baseAuthUriV5,
        'ce-claims-aggregate-api/rest/documents',
        '1',
        'claims/eob',
        { rallyId },
        queryParams,
      );
    }
  },
  healthcareClaimsEOBLink: (rallyId: string, documentKey: string, queryParams: object) => {
    const claimDocumentUriV5 = baseAuthUriV5 + '/id/[documentKey]';
    if (FEATURE_FLAGS.ARCADE_FEATURES_USE_CE_CLAIMS_AGGREGATE_API) {
      return constructUri(
        claimDocumentUriV5,
        'ce-claims-aggregate-api/rest/documents',
        '1',
        'claims/eob',
        { rallyId, documentKey },
        queryParams,
      );
    }
  },
  healthcareClaimsEOBNonHMOLink: (rallyId: string, queryParams: object) => {
    const claimDocumentUriV5 = baseAuthUriV5 + '/nonhmo/';
    if (FEATURE_FLAGS.ARCADE_FEATURES_USE_CE_CLAIMS_AGGREGATE_API) {
      return constructUri(
        claimDocumentUriV5,
        'ce-claims-aggregate-api/rest/documents',
        '1',
        'claims/eob',
        { rallyId },
        queryParams,
      );
    }
  },
  healthStatementList: (rallyId: string, queryParams?: object) => {
    const healthStatementUriV5 = baseAuthUriV5 + '/list';
    if (FEATURE_FLAGS.ARCADE_FEATURES_USE_CE_CLAIMS_AGGREGATE_API) {
      return constructUri(
        healthStatementUriV5,
        'ce-claims-aggregate-api/rest/documents',
        '1',
        'claims/healthStatement',
        { rallyId },
        queryParams,
      );
    }
  },
  healthStatementPdf: (rallyId: string, documentId: string) => {
    const healthStatementUriV5 = baseAuthUriV5 + '/id/[documentId]';
    if (FEATURE_FLAGS.ARCADE_FEATURES_USE_CE_CLAIMS_AGGREGATE_API) {
      return constructUri(
        healthStatementUriV5,
        'ce-claims-aggregate-api/rest/documents',
        '1',
        'claims/healthStatement',
        { rallyId, documentId },
      );
    }
  },
};

export const ledgerUris = {
  balance: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/ledger', '2', 'balance', { rallyId });
  },
};

export const planUris = {
  benefits: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/plans', '1', 'benefits', { rallyId }, queryParams);
  },
  familyBenefits: (rallyId: string, queryParams: object) => {
    const familyBenefitsUri = baseAuthUri + '/family';
    return constructUri(familyBenefitsUri, 'edge-dashboard/plans', '2', 'benefits', { rallyId }, queryParams);
  },
  accumulator: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/plans', '1', 'accumulator', { rallyId }, queryParams);
  },
  familyAccumulator: (rallyId: string, queryParams: object) => {
    const familyAccumulatorsUri = baseAuthUri + '/family';
    return constructUri(familyAccumulatorsUri, 'edge-dashboard/plans', '2', 'accumulator', { rallyId }, queryParams);
  },
  idCards: (rallyId: string, cardType: string, queryParams?: object) => {
    const idCardsUri = baseAuthUri + '/cardType/[cardType]';
    return constructUri(idCardsUri, 'edge-dashboard/plans', '2', 'idcards', { rallyId, cardType }, queryParams);
  },
};

export const preferencesUris = {
  paperlessPreference: () => {
    return constructUri(baseApiUri, 'edge-dashboard/preferences', '1', 'id-card/');
  },
  updatePaperlessPreference: () => {
    return constructUri(baseApiUri, 'edge-dashboard/preferences', '1', 'id-card/update');
  },
};

export const profileUris = {
  autoPaymentPreferences: (rallyId: string) => {
    const autoPaymentUri = baseAuthUri + '/autosubmission';
    return constructUri(autoPaymentUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  claimsPreferences: (rallyId: string) => {
    const claimsUri = baseAuthUri + '/claims';
    return constructUri(claimsUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  communicationPreferences: (rallyId: string) => {
    const commPrefsUri = baseAuthUri + '/communication';
    return constructUri(commPrefsUri, 'edge-dashboard/profile', '2', 'preferences', { rallyId });
  },
  decryptProvider: (rallyId: string, queryParams: object) => {
    const decryptProviderUri = baseAuthUri + '/decrypt';
    return constructUri(decryptProviderUri, 'edge-dashboard/profile', '1', 'redirect', { rallyId }, queryParams);
  },
  eftPreferences: (rallyId: string) => {
    const eftUri = baseAuthUri + '/eft';
    return constructUri(eftUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  healthcareCoverages: (rallyId: string, queryParams?: object) => {
    return constructUri(
      baseAuthUri + '/healthcarecoverages',
      'edge-dashboard/profile',
      '1',
      'profile',
      { rallyId },
      queryParams,
    );
  },
  instamedMobileToken: (rallyId: string, queryParams?: object) => {
    return constructUri(
      baseAuthUri + '/mobiletoken',
      'edge-dashboard/profile',
      '1',
      'instamed',
      { rallyId },
      queryParams,
    );
  },
  medicareNudgeEligibility: (policyId: string) => {
    const medicareNudgeUri = baseApiUri + '/eligible';
    return constructUri(medicareNudgeUri, 'edge-dashboard/profile', '1', 'medicare-nudge', {}, { policyId });
  },
  paperlessPreferences: (rallyId: string) => {
    const paperlessUri = baseAuthUri + '/paperless';
    return constructUri(paperlessUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  paperlessPreferencesV2: () => {
    const paperlessUri = baseApiUri + '/session/communication';
    return constructUri(paperlessUri, 'edge-dashboard/profile', '2', 'preferences');
  },
  pcpSchedulingAuth: () => {
    const pcpSchedulingUri = baseApiUri + '/primarycare/token';
    return constructUri(pcpSchedulingUri, 'edge-dashboard/profile', '1', 'doctor');
  },
  fpcPrimaryCare: (
    rallyId: string,
    queryParams?: {
      lineOfBusiness: LineOfBusiness;
      membershipCategory: MembershipCategory;
      selectPcpEligible: boolean;
      d: string;
    },
  ) => {
    const primaryCareUri = baseAuthUri + '/primarycare/fpc';
    return constructUri(primaryCareUri, 'edge-dashboard/profile', '3', 'doctor', { rallyId }, queryParams);
  },
  primaryCare: (rallyId: string, queryParams?: object) => {
    const primaryCareUri = baseAuthUri + '/primarycare';
    return constructUri(primaryCareUri, 'edge-dashboard/profile', '2', 'doctor', { rallyId }, queryParams);
  },
  products: (rallyId: string) => {
    const productsUri = baseAuthUri + '/products';
    return constructUri(
      productsUri,
      'edge-dashboard/profile',
      '1',
      'profile',
      { rallyId },
      { ignoreTermedFilter: true },
    );
  },
  cspaRewards: (rallyId: string) => {
    const cspaRewardsUri = baseAuthUri + '/getCspaRewards';
    return constructUri(cspaRewardsUri, 'edge-dashboard/profile', '1', 'profile', { rallyId });
  },

  productsV2: (rallyId: string) => {
    const productsUri = baseAuthUri + '/products';
    return constructUri(productsUri, 'edge-dashboard/profile', '2', 'profile', { rallyId });
  },
  profile: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/profile', '2', 'profile', { rallyId }, queryParams);
  },
  profileInfoForRallyPayV3: (rallyId: string, serviceStartDate: string) => {
    const profileInfoForRallyPayUri = baseAuthUri + '/token';
    return constructUri(
      profileInfoForRallyPayUri,
      'edge-dashboard/profile',
      '3',
      'rallypay',
      { rallyId },
      { serviceStartDate },
    );
  },
  providerVideoKey: (rallyId: string, queryParams: object) => {
    const providerVideoKeyUri = baseAuthUri + '/providervideokey';
    return constructUri(providerVideoKeyUri, 'edge-dashboard/profile', '1', 'profile', { rallyId }, queryParams);
  },
  referrals: (rallyId: string) => {
    const referralsUri = baseAuthUri + '/referrals';
    return constructUri(referralsUri, 'edge-dashboard/profile', '1', 'doctor', { rallyId });
  },
  userPreferences: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/profile', '2', 'preferences', { rallyId });
  },
  financialPreferences: (rallyId: string) => {
    const financialUri = baseAuthUri + '/financial';
    return constructUri(financialUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  genoaToken: (rallyId: string) => {
    const profileTokenUri = baseAuthUri + '/token';
    return constructUri(profileTokenUri, 'edge-dashboard/profile', '1', 'genoa', { rallyId });
  },
  orderCovidTestKitToken: () => {
    return constructUri(baseApiUri, 'edge-dashboard/profile', '1', 'order-covid-tests/token');
  },
  accountCards: () => {
    return constructUri(baseApiUri, 'edge-dashboard/profile', '1', 'accountCards', null, { multiYear: true });
  },
  // Get UHC rewards elibility flag.
  // 400 if eligibility id is not found; otherwise 200 with true/false.
  uhcRewards: () => {
    return constructUri(baseApiUri, 'edge-dashboard/profile', '1', 'uhcRewards');
  },
  permissions: (rallyId: string, enterpriseId: string) => {
    const permissionsUri = baseAuthUri + '/[enterpriseId]';
    return constructUri(permissionsUri, 'edge-dashboard/profile', '1', 'permissions', { rallyId, enterpriseId });
  },
};

export const tacoUris = {
  // BE endpoint: GET /rest/edge-dashboard/campaign/v1/campaigns/session with the following parameters.
  // With default parameters as implemented in arcade-targeting.
  targetedContent: (queryParams: object) => {
    const defaultQueryParams = {
      placementType: [],
      limitPerPlacement: 20,
      includeCompletedActivities: true,
      includeUnincentedActivities: true,
    };
    const params = { ...defaultQueryParams, ...queryParams };
    return constructUri(baseApiUri, 'edge-dashboard/campaign', '1', 'campaigns/session', undefined, params);
  },
  trackEvent: () => {
    return constructUri(baseApiUri, 'edge-dashboard/campaign', '1', 'trackEvent');
  },
  trackEventV2: () => {
    return constructUri(baseApiUri, 'edge-dashboard/campaign', '2', 'trackEvent');
  },
  trackEventsV2: () => {
    return constructUri(baseApiUri, 'edge-dashboard/campaign', '2', 'trackEvents');
  },
};

export const targetingUris = {
  alerts: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '1', 'alerts', { rallyId });
  },
  campaigns: (rallyId: string, queryParams: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '2', 'campaigns', { rallyId }, queryParams);
  },
  careRecommendationClick: () => {
    return constructUri(pythiaUri, 'pythia', '1', 'banzai/click');
  },
  clientConfig: (configId: string, queryParams: object) => {
    const clientConfigUri = baseApiUri + '/[configId]/configuration';
    return constructUri(clientConfigUri, 'edge-dashboard/targeting', '2', 'client', { configId }, queryParams);
  },
  csFaqCustomizations: (planName: string) => {
    const csFaqCustomizationsUri = baseApiUri + '/[planName]/custom';
    return constructUri(csFaqCustomizationsUri, 'edge-dashboard/targeting', '1', 'client', { planName });
  },
  realTimeOfferCount: (rallyId: string, queryParams?: object) => {
    return constructUri(
      baseAuthUri + '/count',
      'edge-dashboard/targeting',
      '2',
      'realtimeoffers',
      { rallyId },
      queryParams,
    );
  },
  recommendations: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '1', 'realtimeoffers', { rallyId });
  },
  recommendationsV2: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '2', 'realtimeoffers', { rallyId });
  },
  recommendationImpression: () => {
    return constructUri(pythiaUri, 'pythia', '1', 'banzai/impression');
  },
  tacoRealTimeOffers: (rallyId: string, queryParams: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '1', 'taco/realtimeoffers', { rallyId }, queryParams);
  },
  tacoRecommendations: (rallyId: string, queryParams: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '1', 'taco/recommendations', { rallyId }, queryParams);
  },
};

export const trackingUris = {
  events: () => {
    return constructUri(baseApiUri, 'tracking', '1', 'events');
  },
};

function heartbeatOrInfoUri(method: string): string {
  const userUri = baseApiUri + `/${method}`;
  return constructUri(userUri, 'user', '1', 'session');
}

export const arachneUris = {
  baseUri: (config: IConfig) => {
    return config.ARCADE_WEB_ARACHNE_SESSION_BASE_URL || config.ARCADE_WEB_RALLY_AUTH_URL;
  },
};

export const ssoUris = {
  annexToken: () => constructUri(baseApiUri, 'edge-dashboard/sso', '2', 'annex'),
};

export const userUris = {
  info: () => {
    return heartbeatOrInfoUri('info');
  },
  heartbeat: () => {
    return heartbeatOrInfoUri('heartbeat');
  },
  idp: (idp: string, queryParams?: object) => {
    const idpUri = baseApiUri + '/idp/[idp]';
    // This needs to use no encoding because the entire redirect url needs to be encoded at once (this is only a part of it).
    return constructUri(idpUri, 'user', '1', 'session', { idp }, queryParams, constructParamsNoEncoding);
  },
  idpLogoutPage: (population: IPopulation, config = CONFIG) => {
    if (config.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Optum) {
      const resource: IResource =
        population.lineOfBusiness === LineOfBusiness.MR
          ? ({ myUhcNoDuBaseUrl }) => `${myUhcNoDuBaseUrl}/member/logout.html`
          : ({ myUhcBaseUrl }) => `${myUhcBaseUrl}${config.ARCADE_WEB_MYUHC_LOGOUT_PATH}`;
      return getResource(resource, population);
    } else {
      const redirectUrl = parse(userUris.idp('rally', { product: 'arcade' })).href;
      const rallyLogoutPage = config.ARCADE_WEB_RALLY_AUTH_URL + config.ARCADE_WEB_RALLY_AUTH_LOGOUT_PATH;
      const sessionCreationEndpoint = encodeURIComponent(redirectUrl);
      return `${rallyLogoutPage}/?redirect=${sessionCreationEndpoint}`;
    }
  },
  idpRefresh: (config = CONFIG) => {
    const idpRefreshUri = baseApiUri + '/idp/[idp]/refresh';
    let authType = 'rally';

    if (config.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Optum) {
      authType = 'myuhc';
    }

    return constructUri(idpRefreshUri, 'user', '1', 'session', { idp: authType });
  },
  logout: () => {
    const logoutUri = baseApiUri + '/logout';
    return constructUri(logoutUri, 'user', '1', 'session');
  },
  arachneLogout: (config = CONFIG) => {
    return config.ARCADE_WEB_RALLY_AUTH_URL + '/auth/v1/logout';
  },
  planToken: (token = '') => {
    const uri = baseApiUri + `/set-selected-plan/${token}`;
    return constructUri(uri, 'user', '1', 'plan-selector');
  },
  prelogin: () => {
    const uri = baseApiUri + '/prelogin';
    return constructUri(uri, 'user', '1', 'session');
  },
  token: (url: string) => {
    const uri = baseApiUri + '/token';
    return constructUri(uri, 'user', '1', 'federation', {}, { deeplink: url });
  },
  sundog: (rallyId: string) => {
    const sundogUri = baseAuthUri + '/sundogdental';
    return constructUri(sundogUri, 'user', '1', 'redirect', { rallyId });
  },
  rallyPayToken: () => {
    const userUri = baseApiUri + '/token';
    return constructUri(userUri, 'user', '1', 'rallypay');
  },
  rallyPayTokenV2: () => {
    const userUri = baseApiUri + '/token';
    return constructUri(userUri, 'user', '2', 'rallypay');
  },

  withSelectedPlan: (planToken: string, locale?: string, config: IConfig = CONFIG) => {
    const baseUri = config.ARCADE_WEB_BASE_URL;
    const uri = `${baseUri}/client/user/v1/session/with-selected-plan?planToken=${encodeURIComponent(planToken)}`;
    if (locale) {
      return `${uri}&locale=${encodeURIComponent(locale)}`;
    } else {
      return uri;
    }
  },
};

export const connectServiceUris = {
  lastIndexed: (partnerId: PartnerId, coverageTypes: LastIndexedCoverageType[], config = CONFIG) => {
    if (coverageTypes.length > 0) {
      return (
        `${config.ARCADE_WEB_BASE_API_URL}/rest/edge-dashboard/connect/v1/lastIndexed` +
        constructParams({ partnerId, coverageType: coverageTypes })
      );
    } else {
      throw 'Must provide at least one coverage type to the Rally Connect "LastIndexed" endpoint.';
    }
  },
};

const kamajiServiceUri = (path, api, method, version = '', config: IConfig = CONFIG, queryParams?: object): string => {
  const baseKamajiApiUri = `${config.ARCADE_WEB_REWARDS_MOCK_BASE_URL}${path}`;
  if (!baseKamajiApiUri.includes('[version]')) {
    return constructUriWithoutVersion(baseKamajiApiUri, api, method, queryParams);
  }
  return constructUri(baseKamajiApiUri, api, version, method, {}, queryParams);
};

export const incentivesServiceUris = {
  programOverviewEligibility: (useKamaji: boolean = FEATURE_FLAGS.ARCADE_FEATURES_REWARDS_USE_KAMAJI) => {
    if (useKamaji) {
      return kamajiServiceUri('/rest/rewards/[api]/[method]', 'incentives', 'hasPO', undefined, CONFIG, {
        respectSuppression: true,
      });
    }
    const baseIncentivesHasPOApiUri = (baseUrls.api || '') + '/rest/rewards/[api]/[method]';
    return constructUriWithoutVersion(baseIncentivesHasPOApiUri, 'incentives', 'hasPO', {
      respectSuppression: true,
    });
  },
  programOverview: (useKamaji: boolean = FEATURE_FLAGS.ARCADE_FEATURES_REWARDS_USE_KAMAJI) => {
    if (useKamaji) {
      return kamajiServiceUri('/rest/rewards/[api]/v[version]/[method]', 'incentives', 'programOverview', '4', CONFIG, {
        respectSuppression: true,
      });
    }
    const baseIncentivesApiUri = (baseUrls.api || '') + '/rest/rewards/[api]/v[version]/[method]';
    return constructUri(baseIncentivesApiUri, 'incentives', '4', 'programOverview', {}, { respectSuppression: true });
  },
};

export const speakEasyUris = {
  availability: (config: IConfig = CONFIG) => {
    if (config.ARCADE_WEB_ENVIRONMENT_NAME === ArcadeEnvironment.Local) {
      return config.ARCADE_WEB_RALLY_SPEAK_EASY_BASE_URL + '/rest/chat-uhc/speakeasy/v1/member/availability';
    }
    return config.ARCADE_WEB_RALLY_SPEAK_EASY_BASE_URL + '/speakeasy/v1/member/availability';
  },
  eligibility: (config: IConfig = CONFIG) => {
    if (config.ARCADE_WEB_ENVIRONMENT_NAME === ArcadeEnvironment.Local) {
      return config.ARCADE_WEB_RALLY_SPEAK_EASY_BASE_URL + '/rest/chat-uhc/speakeasy/v1/member/eligibility';
    }
    return config.ARCADE_WEB_RALLY_SPEAK_EASY_BASE_URL + '/speakeasy/v1/member/eligibility';
  },
};

export const telemedicineEligibilityUris = {
  eligibility: (rallyId: string, config: IConfig = CONFIG) => {
    return `${config.ARCADE_WEB_BASE_API_URL}/rest/edge-dashboard/virtualcare/v1/eligibility/${encodeURIComponent(
      rallyId,
    )}/costshare`;
  },
  eligibilityV4: (config: IConfig = CONFIG) => {
    return `${config.ARCADE_WEB_BASE_API_URL}/rest/edge-dashboard/virtualcare/v4/eligibility/planselector`;
  },
};

const getSanitizedUrls = (config: IConfig): { connect: string; engage: string; rewards: string } => {
  return {
    connect: sanitizeLink(config.ARCADE_WEB_RALLY_CONNECT_URL),
    engage: sanitizeLink(config.ARCADE_WEB_RALLY_ENGAGE_URL),
    rewards: sanitizeLink(config.ARCADE_WEB_RALLY_REWARDS_URL),
  };
};

/**
 * Determines if the url has connect, engage, or rewards in it
 */
export function shouldBeInternalRedirectSso(link: string, config: IConfig = CONFIG): boolean {
  const sanitizedUrls = getSanitizedUrls(config);
  return (
    link.indexOf(sanitizedUrls.connect) > -1 ||
    link.indexOf(sanitizedUrls.engage) > -1 ||
    link.indexOf(sanitizedUrls.rewards) > -1
  );
}

/**
 * Appends ARCADE_WEB_INTERNAL_REDIRECT if the url has connect, engage, or rewards in it
 */
export function getLink(link: string, rallyCaller: RallyCaller, config: IConfig = CONFIG): string {
  return shouldBeInternalRedirectSso(link, config)
    ? `${ARCADE_WEB_INTERNAL_REDIRECT}${encodeURIComponent(link)}&rallyCaller=${rallyCaller}`
    : link;
}

// extracts the ssoUrl deeplink from a full internal-redirect URL and returns just the ssoUrl deeplink
export function getSsoUrl(href: string): string {
  return decodeURIComponent(href.split(new RegExp('deepLink=', 'ig')).pop());
}

/**
 * Checks whether the input link is an outbound sso by
 * looking for ARCADE_WEB_INTERNAL_REDIRECT within the url.
 * @param link
 */
export function isInternalRedirectSso(link: string): boolean {
  const internalRedirectLowerCase = ARCADE_WEB_INTERNAL_REDIRECT.toLowerCase();
  const linkLowerCase = link.toLowerCase();
  return linkLowerCase.includes(internalRedirectLowerCase);
}

/**
 * Is a url owned by arcade. We check if it matches our base url then see if we have a path that matches.
 * @param link
 */
export function isLinkToArcade(link: string): boolean {
  if (!link) {
    return false;
  }
  let path = '';
  // Try catch to allow custom events such as phone numbers and such
  try {
    // Check if it's the url is just our base url
    const unparsedPath = new URL(decodeURI(link)).pathname;
    if (unparsedPath === '' || unparsedPath === '/') {
      return link.includes(baseUrls.web);
    }
    // Remove ONLY trailing slash
    path = unparsedPath.replace(/\/$/, '');
  } catch {
    // Not a valid url, yeet
    return false;
  }

  const exemptRoutes = new Set([
    '/internal-redirect',
    '/active',
    '/rewards',
    '/activate',
    '/pharmacy-uhc',
    '/takeaction',
  ]);
  if (exemptRoutes.has(path)) {
    return false;
  }
  return link.includes(baseUrls.web) && PagesDataByPathAsList.some(str => str.includes(path));
}

export function isArcadeAdvantageExternalLink(anchor: HTMLAnchorElement, location: Location): boolean {
  const isInternalRedirect = anchor.pathname.split('/').pop() === 'internal-redirect';
  const isClaimsHref = isLinkToClaimsUi(anchor.href);
  const isDifferentHostname = anchor.hostname !== location.hostname;
  return isInternalRedirect || isClaimsHref || isDifferentHostname;
}

export function isLinkToClaimsUi(link: string, config: IConfig = CONFIG): boolean {
  const linkUrl = parse(link);
  const claimsUrl = parse(config.ARCADE_WEB_RALLY_CLAIMS_URL);
  if (claimsUrl.host !== linkUrl.host) {
    return false;
  }
  const claimsPathNames = ['/claims-and-accounts'];
  return claimsPathNames.some(claimsPathName => linkUrl.pathname.indexOf(claimsPathName) > -1);
}

/*
  - prepends pathname of the current page to the modal pathname
  - appends search of the current page to the modal search
  - stores currentRoute (pathname + search) in history state

  Limitations: currently doesn't work if a modal navigates to another modal

  Examples:
  input a: '/modal/modal-name?a=1'
  input b: {
    pathname: '/modal/modal-name',
    search: '?a=1',
  }

  both inputs returns
  {
    pathname: '/dashboard/modal/modal-name',
    search: '?filters=asdf&a=1',
    state: { previousRoute: '/dashboard?filters=asdf' },
  }
  for current route: /dashboard?filters=asdf
*/
function getModalRoute(to: LocationDescriptor): LocationDescriptorObject {
  const { pathname: currentPathname, search: currentSearch } = window.location;
  const currentRoute = `${currentPathname}${currentSearch}`;
  let modalPathname: string, modalSearchWithoutQuestionMark: string;

  if (typeof to === 'string') {
    [modalPathname, modalSearchWithoutQuestionMark] = to.split('?');
  } else if (to && to.pathname) {
    modalPathname = to.pathname;
    modalSearchWithoutQuestionMark = (to.search || '').replace('?', '');
  }

  const newPathname = `${currentPathname}${modalPathname}`;
  let newSearch = currentSearch;
  if (modalSearchWithoutQuestionMark) {
    newSearch = newSearch ? `${newSearch}&${modalSearchWithoutQuestionMark}` : `?${modalSearchWithoutQuestionMark}`;
  }
  return {
    pathname: newPathname,
    search: newSearch,
    state: {
      previousRoute: currentRoute,
    },
  };
}

export function addModalPath(to: LocationDescriptor): LocationDescriptor {
  if (typeof to === 'string') {
    return to.indexOf('/modal') === 0 ? getModalRoute(to) : to;
  } else if (to && to.pathname) {
    return to.pathname.indexOf('/modal') === 0 ? getModalRoute(to) : to;
  }
  return to;
}

// If there is a population path and it isn't prepended, it prepends it
export function addPopulationPath(to: LocationDescriptor, populationPath: string): LocationDescriptor {
  if (!populationPath) {
    return to;
  }
  if (typeof to === 'string') {
    return to.indexOf(populationPath) !== 0 ? `${populationPath}${to}` : to;
  } else if (to && to.pathname) {
    return {
      ...to,
      pathname: to.pathname.indexOf(populationPath) !== 0 ? `${populationPath}${to.pathname}` : to.pathname,
    };
  }
  return to;
}

export const getDashboardPath = (populationUrl: string | undefined): string => {
  const dashboardRoute = PagesData.dashboard.path;
  return populationUrl ? populationUrl + dashboardRoute : dashboardRoute;
};

// Determines if current path is a modal
export const isModalPath = (pathname: string): boolean => {
  return pathname.includes('/modal/');
};

export const isDeeplinkedPath = (search: string): boolean => {
  return new URLSearchParams(search).has('deeplink');
};

export const isIDCardPath = (pathname: string): boolean => {
  return pathname.includes('/dashboard/modal/id-cards');
};

export const isCIDDeeplinkedPath = (search: string): boolean => {
  return new URLSearchParams(search).has('cid');
};

const createCurrentPagePathSegment = (
  isCurrentPathModal: boolean,
  pathSegments: string[],
  modalIdx: number,
  dashIdx: number,
): string => {
  if (isCurrentPathModal) {
    return `/modal/${pathSegments[modalIdx + 1]}`;
  } else {
    // /dashboard path
    if (pathSegments[pathSegments.length - 1].match(/dashboard/i)) {
      return PagesData.dashboard.path;
    }

    // Dashboard path with remaining path -> /dashboard/validPath
    if (dashIdx === 0) {
      return `/${pathSegments[dashIdx + 1]}`;
    }

    // Path without Dashboard => /help
    return `/${pathSegments.pop()}`;
  }
};

export function getPageDataForPathname(pathname: string): IPageData | undefined {
  const isCurrentPathModal = isModalPath(pathname);
  // /dashboard -> ['', 'dashboard'] -> ['dashboard']
  // /dashboard/ -> ['', 'dashboard', ''] -> ['dashboard']
  const pathSegments = pathname.split('/').filter(Boolean);
  if (pathSegments.length === 0) {
    return undefined;
  }
  const modalSegmentIndex = pathSegments.indexOf('modal');
  const dashboardSegmentIndex = pathSegments.indexOf('dashboard');

  // TODO: ARC-12499 Update getRemainingPath Arguments for Advantage Users
  // non modal example: https://myuhc.com/dashboard/ -> /dashboard
  // example: https://myuhc.com/help -> /help
  // modal example: https://myuhc.com/dashboard/modal/account-faq -> /modal/account-faq
  const currentPagePathSegment = createCurrentPagePathSegment(
    isCurrentPathModal,
    pathSegments,
    modalSegmentIndex,
    dashboardSegmentIndex,
  );
  return PagesDataByPath[currentPagePathSegment];
}
//
export const rewardsUris = {
  getEnrollmentStatus: () => (baseUrls.api || '') + '/rest/rewards/incentives/enrollmentStatus',
  getCardBalance: (rallyId: string) =>
    (baseUrls.api || '') +
    `${
      FEATURE_FLAGS.ARCADE_FEATURES_UCARD_WEB_CSPA_API_ENABLED
        ? '/rest/edge-dashboard/ucard/v3/account/'
        : '/rest/rewards/incentives/ucard/v2/account/'
    }${rallyId}/balance`,
  postRewardsEligibility: () => (baseUrls.web || '') + '/rewards/public/incentives-program/eligibility/read',
  getFhubFulfillment: (rallyId: string) => {
    return constructUri(baseApiUri, 'edge-dashboard/ucard', '1', `replace/fulfillment/${rallyId}`);
  },
  rallyRewardsV2: () => {
    return baseUrls.web + '/rest/arcade/espresso/loyalty/products/rally2.0/eligibility';
  },
};

export const rewardsOdinUri = constructUri(baseApiUri, 'edge-dashboard/odin', '1', 'graphql');

export const BFF_PATHS = {
  COMPONENTS: '/rest/arcade/espresso/integrated',
  POSTEVENTS_URL: '/rest/onboarding-api/espresso/v1/tasks/events',
};
