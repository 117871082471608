import {
  ICampaign,
  IClientConfig,
  OutcomeType,
  HelpFaqType,
  ITacoRecommendation,
} from 'scripts/api/targeting/targeting.interfaces';
import { SAME_TAB_PROMO_LINKS, NEW_TAB_PROMO_LINKS } from 'scripts/util/population/population.constants';
import { parse } from 'scripts/util/uri/parse';
import cloneDeep from 'lodash.clonedeep';

export function hasPayNowSuppression(clientConfig: IClientConfig): boolean {
  return !!clientConfig?.suppressions?.payNowSuppression;
}

export function hasRallypaySuppression(clientConfig: IClientConfig): boolean {
  return !!clientConfig?.suppressions?.rallypaySuppression;
}

export function hasRallypayRolloutEnabledSuppression(clientConfig: IClientConfig): boolean {
  return !!clientConfig?.suppressions?.rallypayRolloutEnabled;
}

export function hasShowMerpLabel(clientConfig: IClientConfig): boolean {
  return !!clientConfig?.suppressions?.showMERPLabel;
}

export const isJPMCFaqType = (clientConfig: IClientConfig): boolean => {
  return clientConfig?.contentOverrides?.helpFAQTypes?.indexOf(HelpFaqType.JPMC) > -1;
};

export function shouldOpenPromoInSameTab(link: string): boolean {
  const anchor = parse(link);
  const hostname = anchor.hostname.indexOf('www.') === 0 ? anchor.hostname.substr(4) : anchor.hostname;
  const ssoOutBound = anchor.pathname.indexOf('ssoOutBound') >= 0 || anchor.pathname.indexOf('sso/outbound') >= 0;
  return (
    !ssoOutBound &&
    !NEW_TAB_PROMO_LINKS[anchor.pathname] &&
    (hostname === location.hostname || !!SAME_TAB_PROMO_LINKS[hostname])
  );
}

/**
 * Returns true if the campaign is valid.
 * @param promo The {@see ICampaign}
 */
export function isIncentiveValid(promo: ICampaign): boolean {
  return (
    !!promo.metadata &&
    (promo.metadata.outcome === OutcomeType.GatekeeperActivity || promo.metadata.outcome === OutcomeType.RewardOpen)
  );
}

export function isTacoIncentiveValid(recommendationOutcome: OutcomeType): boolean {
  return recommendationOutcome === OutcomeType.GatekeeperActivity || recommendationOutcome === OutcomeType.RewardOpen;
}

export function parseTacoRecommendationOutcome(recommendation: ITacoRecommendation): OutcomeType | undefined {
  return recommendation.metadata?.activityId?.outcome;
}

export function parseTacoRewardText(recommendation: ITacoRecommendation): string | undefined {
  return recommendation.metadata?.activityId?.rewardValue;
}

export function parseTacoOfferKey(recommendation: ITacoRecommendation): string | undefined {
  return recommendation.trackingInfo?.memberActionsTrackingData?.offerKey;
}

export function hasOnlineMedicalAppeals(clientConfig: IClientConfig): boolean {
  return !!clientConfig?.suppressions?.onlineMedicalAppeals;
}

export function hasSuppressNetworkIndicators(clientConfig: IClientConfig): boolean {
  return !!clientConfig?.suppressions?.suppressNetworkIndicators;
}

export const sortCampaignListByPriority = (list: ICampaign[]): ICampaign[] => {
  if (list && Array.isArray(list)) {
    return [...list].sort((a, b) => b.priority - a.priority);
  } else {
    return list;
  }
};

/**
 * setTotalSlotsValue takes in a recommendation and the number of recommendations being rendered and sets that on the single recommendation.
 * Every recommendation sent through this function will leave with a totalSlots property, even if it does not already have one.
 * Ticket Ref: https://jira.rallyhealth.com/browse/ARC-12171
 *
 * @param recommendation The individual recommendation from a list of recommendations
 * @param totalSlots Not the totalSlots from the individual recommendation but the total number of recommendations being rendered
 * @returns The single recommendation but with the totalSlots set to the total number of recommendations being rendered
 */

export const setTotalSlotsValue = (recommendation: ITacoRecommendation, totalSlots: number): ITacoRecommendation => {
  const recommendationCopy = cloneDeep(recommendation);
  if (!recommendationCopy.trackingInfo) {
    recommendationCopy.trackingInfo = {};
  }
  recommendationCopy.trackingInfo.totalSlots = totalSlots;
  return recommendationCopy;
};
