export enum Dictionary {
  ACCOUNT_INFO = 'account-info',
  ACCOUNT_SUMMARY = 'account-summary',
  ACCUMULATORS = 'accumulators',
  BENEFITS_OVERVIEW = 'benefits-overview',
  CLAIM = 'claim',
  CLAIM_BALANCES = 'claim-balances',
  COMMON = 'common',
  COMPARE_CARE = 'compare-care',
  CONTACT_US = 'contact-us',
  CORONAVIRUS = 'coronavirus',
  COSTS = 'costs',
  ERRORS = 'errors',
  FAQ = 'faq',
  ID_CARDS = 'id-cards',
  INACTIVE = 'inactive',
  MAILING_SUMMARY_MODAL = 'mailing-summary-modal',
  MEDICARE_ELIGIBILITY = 'medicare-eligibility',
  MEDICARE_ELIGIBILITY_POST_65 = 'medicare-eligibility-post-65',
  NOTIFICATION = 'notification',
  ONBOARDING = 'onboarding',
  PAGE_TITLES = 'page-titles',
  PAY_NOW_MODAL = 'pay-now-modal',
  PCP = 'pcp',
  PHARMACY = 'pharmacy',
  PREMIUM_PAYMENTS = 'premium-payments',
  PRESCRIPTIONS = 'prescriptions',
  PROGRAMS = 'programs',
  RECOMMENDATIONS = 'recommendations',
  RESOURCES = 'resources',
  SEE_YOU_LATER = 'see-you-later',
  SUPER_USER_BANNER = 'super-user-banner',
  WELLNESS = 'wellness',
  WAYS_TO_SAVE = 'ways-to-save',
  REWARDS_WIDGETS = 'rewards-widgets',
  ID_CARD_ACCESSIBILITY = 'id-card-accessibility',
  SAM_BANNER = 'sam-banner',
}

export const LocaleKey = 'ARCADE_LOCALE';
export const SessionLocaleKey = 'ARCADE_SESSION_LOCALE';
export const MyUhcLocaleKey = 'MYUHC_LOCALE';

export enum TranslationContext {
  PMP = 'PMP',
}
