import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { IResource, ResourceFunction } from './resource.interfaces';
import { constructParams } from 'scripts/util/uri/construct-params';
import CONFIG from '../constants/config';
import FEATURE_FLAGS from '../constants/feature-flag';
export const activate: IResource = ({ id }) => `/activate?locale=${id}`;
export const advantage: IResource = ({ advantageUrl }) => `${advantageUrl}`;
export const connect: IResource = ({ connectUrl, id }) => `${connectUrl}?locale=${id}`;
export const rewardsMissingRewards: IResource = ({ rallyRewardsUrl }) => `${rallyRewardsUrl}/whereismyreward/`;
export const rewardsProgramOverview: IResource = ({ rallyRewardsUrl }) => `${rallyRewardsUrl}/program-overview/`;
export const rx: IResource = ({ rxUrl }) => `${rxUrl}`;
export const benefitsCoverage: IResource = ({ myUhcBaseUrl, lineOfBusiness, id }) => {
  switch (lineOfBusiness) {
    case LineOfBusiness.MR:
      return `${myUhcBaseUrl}/uhc/benefits`;
    case LineOfBusiness.CS:
      return `${myUhcBaseUrl}/community/benefits${constructParams({ locale: id })}`;
    default:
      return `${myUhcBaseUrl}/coverage${constructParams({ locale: id })}`;
  }
};
export const benefitsCoverageMedical: IResource = ({ myUhcBaseUrl, id }) =>
  `${myUhcBaseUrl}/coverage${constructParams({ locale: id })}`;
export const benefitsCoveragePharmacy: IResource = ({ myUhcBaseUrl, id }) =>
  `${myUhcBaseUrl}/coverage/prescriptions/pharmacy-locator${constructParams({ locale: id })}`;
export const manageOptumBankAccount: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_OptumBank_ACCTDETAIL`;
export const idCardsByMail: IResource = {
  default: ({ myUhcBaseUrl, id }) => {
    return `${myUhcBaseUrl}/myuhc/accounts/idcard/mail${constructParams({ locale: id })}`;
  },
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => {
    return `${myUhcBaseUrl}/medicare/order-materials`;
  },
  [LineOfBusiness.CS]: ({ myUhcLegacyBaseUrl }) =>
    `${myUhcLegacyBaseUrl}/member/submitQuesMedicaidStepAem.do?questionType=idcardrequest`,
};

export const idCardsByMailCSMdcd: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/communityplan/help-ask-a-question-mail-id-card`;

export const contactUs: IResource = {
  default: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/contact-us/overview.html`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => {
    return `${myUhcBaseUrl}/medicare/help`;
  },
  [LineOfBusiness.CS]: ({ myUhcBaseUrl }) => {
    return `${myUhcBaseUrl}/communityplan/messagecenter`;
  },
};
export const documents: IResource = ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}/member/myDocumentslayoutAem.do`;
export const accountSettings: IResource = {
  default: ({ myUhcBaseUrl, language }) => `${myUhcBaseUrl}/content/myuhc/${language}/secure/account-profile.html`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => {
    return `${myUhcBaseUrl}/account`;
  },
  [LineOfBusiness.CS]: ({ myUhcBaseUrl, language }) =>
    `${myUhcBaseUrl}/content/myuhc/${language}/secure/communityplan/account-profile.html`,
};
export const claimAccountBalances: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/account-balance.html`;
export const instamedPayNow: IResource = ({ myUhcNoDuBaseUrl }) =>
  `${myUhcNoDuBaseUrl}/member/claimsSsoOutBoundAEM.do?partner=sso_SSOPartner_INSMEMPAY&key=`;
export const askAQuestion: ResourceFunction = ({ myUhcBaseUrl, myUhcLegacyBaseUrl, id, lineOfBusiness }) => {
  switch (lineOfBusiness) {
    case LineOfBusiness.EI:
      return `${myUhcBaseUrl}/myuhc/ask-a-question${constructParams({ locale: id })}`;
    case LineOfBusiness.CS:
      return `${myUhcLegacyBaseUrl}/member/submitQuesMedicaidStepAem.do`;
  }
};
export const secureMessageCenter: ResourceFunction = ({ myUhcBaseUrl, id }) => {
  return `${myUhcBaseUrl}/communityplan/ask-a-question${constructParams({ locale: id })}`;
};
export const covid19CoverageAndResources: IResource =
  'https://www.uhc.com/health-and-wellness/health-topics/covid-19/coverage-and-resources';
export const exchangeMonthlyPremiumPayment: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_Cobra_HealthInsurancePremium&csId=15067`;
export const expatGlobalGateway: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_GS_GlobalResources2`;
export const expatHealthResources: ResourceFunction = ({ myUhcBaseUrl, id }) => {
  return `${myUhcBaseUrl}/myuhc/health-resources${constructParams({ locale: id })}`;
};
export const ancillaryBenefits: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/benefits.html#anc-ben`;
export const benefitsDental: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/uhc/benefits/#dental`;
export const benefitsVision: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/uhc/benefits/#vision`;
export const claimsAllClaims: ResourceFunction = ({ claimsUrl }) => `${claimsUrl}/claims`;
export const claimsCsClaims: ResourceFunction = ({ id }) => `/communityplan/claims${constructParams({ locale: id })}`;
export const claimsClaimDetails: IResource = ({ claimsUrl }) => `${claimsUrl}/claim-details`;
export const claimsAccountSummary: IResource = ({ claimsUrl }) => `${claimsUrl}/account-summary`;
export const medicationList: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/prescriptions/myuhc/medication-list`;
export const medicationListOld: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/coverage/prescriptions/medication-list`;
export const orderStatus: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/prescriptions/myuhc/secure/order-status`;
export const orderStatusOld: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/coverage/prescriptions/secure/order-status`;
export const claimsFinancialAccounts: IResource = ({ claimsUrl }) => `${claimsUrl}/financial-accounts`;
export const spendingOverview: IResource = ({ claimsUrl }) => `${claimsUrl}/spending-overview`;
export const claimsAccountSummaryExpanded: ResourceFunction = ({ claimsUrl }) =>
  `${claimsUrl}/account-summary?accountsExpanded=1`;
export const claimsSpendingAndCostSummary: ResourceFunction = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/claims-and-accounts/spending-and-cost-summary`;
export const findDoctor: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/findcare`;
export const psxPcp: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/findcare/eyJjaGlwVmFsdWUiOiJQcmltYXJ5IENhcmUiLCJjb3ZlcmFnZVR5cGUiOiJNIn0=`;
export const medicalSpecialty: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/findcare/eyJjaGlwVmFsdWUiOiJNZWRpY2FsIFNwZWNpYWxpc3RzIiwiY292ZXJhZ2VUeXBlIjoiTSJ9`;
export const claimsSummary: IResource = ({ claimsUrl }) => `${claimsUrl}/summary`;
export const claimsSubmitClaim: ResourceFunction = ({ claimsUrl }) => `${claimsUrl}/submit-claim`;
export const claimsStatements: ResourceFunction = ({ claimsUrl }) => `${claimsUrl}/statements`;
export const connectAmbulatory: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?coverageType=medical&sort=distance&specialtyCategory=375&locale=${id}&showBackButton=true`;
export const connectDoctor: IResource = ({ connectUrl, id }) => `${connectUrl}/find/6?locale=${id}`;
export const connectSpecialist: IResource = ({ connectUrl, id }) => `${connectUrl}/find/7?locale=${id}`;
export const connectUrgent: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?sort=distance&specialtyCategory=372&locale=${id}`;
export const connectEmergent: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?specialtyCategory=13&locale=${id}`;
export const virtualVisits: IResource = ({ virtualCareUrl, id }) => `${virtualCareUrl}/home?locale=${id}`;
export const virtualCarePCPLandingPage: IResource = ({ virtualCareUrl }) => `${virtualCareUrl}/?serviceType=vpcp`;
export const optumVirtualCare: IResource = ({ optumVirtualCareUrl }) =>
  `${optumVirtualCareUrl}/dashboard/sso?source=healthsafeid`;
export const connectOutpatient: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?coverageType=medical&sort=distance&specialtyCategory=13&locale=${id}`;
export const connectInpatient: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?coverageType=medical&sort=distance&specialtyCategory=13&locale=${id}`;
export const connectDurable: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?coverageType=medical&sort=distance&specialtyCategory=15&locale=${id}`;
export const connectPsxProvider: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/findcare?originalPath=/care/provider/`;
export const connectProvider: IResource = ({ connectUrl }) => `${connectUrl}/provider/`;
export const connectQueryLocale: IResource = ({ id }) => `?locale=${id}`;
export const connectPsxQueryPartner: IResource = ({ id }) =>
  `&partnerId=true&chipValue=PrimaryCare&coverageType=M&source=dashboard&lob=M&R&locale=${id}`;
export const connectQueryLocalePartner: IResource = ({ id }) => `?partnerId=true&locale=${id}`;
export const connectReferrals: IResource = ({ connectUrl }) => `${connectUrl}/referral/`;
export const connectMnrPsx: IResource = ({ myUhcBaseUrl, id }) =>
  `${myUhcBaseUrl}/findcare?originalPath=/care/edit-pcp&linkName=/findcare/results?chipValue=PrimaryCare&coverageType=M` +
  `&choosePCP=true&pcpIndicator=true&dependentSeqNbr=00&providerType=M&searchType=speciality&lob=M&R&locale=${id}`;
export const connectEditPcp: IResource = ({ connectUrl, id }) => `${connectUrl}/edit-pcp?locale=${id}`;
export const uhcWestEditPcp: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/pcpupdatememberlayoutaem.do`;
export const connectSaved: IResource = ({ connectUrl, id }) => `${connectUrl}/saved-providers/gallery?locale=${id}`;
export const connectPlans: IResource = {
  default: ({ connectUrl, id }) => `${connectUrl}/plans/uhc?locale=${id}`,
  [LineOfBusiness.MR]: ({ connectUrl }) => `${connectUrl}/county-plan-selection/uhc.mnr/zip`,
};
export const connectMR: IResource = ({ connectUrl, id }) => `${connectUrl}/mr-landing-page?locale=${id}`;
export const drugCoverage: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/benefits.html#drug-benefits`;

export const pharmacy: IResource = () => '/pharmacy';
export const valueAddedServices: IResource = ({ myUhcBaseUrl }) => {
  return `${myUhcBaseUrl}/uhc/benefits/#discountsAndServices`;
};
export const paymentsOverview: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/medicare/payments`;
export const paymentsPremium: ResourceFunction = ({ myUhcBaseUrl }) => {
  return `${myUhcBaseUrl}/community/premium-payments`;
};
export const accountProfile: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/medicare/account`;
export const prepareForNextYearOverview: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/uhc/benefits/prepare-for-next-year`;
export const documentsOverview: IResource = {
  default: '',
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => {
    return `${myUhcBaseUrl}/medicare/documents`;
  },
  [LineOfBusiness.CS]: ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}/member/bcMemberHandbook.do`,
};
export const prescriptions: ResourceFunction = ({ myUhcBaseUrl, lineOfBusiness, rxUrl, id }) => {
  switch (lineOfBusiness) {
    case LineOfBusiness.MR:
      return `${myUhcBaseUrl}/uhc/benefits/prescriptions`;
    case LineOfBusiness.CS:
      return `${myUhcBaseUrl}/communityplan/rx${constructParams({ locale: id })}`;
    default:
      return `${rxUrl}`;
  }
};
export const aarp: IResource = 'https://www.aarp.org';
export const appeals: IResource = 'https://www.personalhealthmessagecenter.com/public/forms/appealsandgrievancesCA';
export const ksAppeal: IResource = 'https://www.personalhealthmessagecenter.com/public/forms/KS-Appeal';
export const ksGrievance: IResource = 'https://www.personalhealthmessagecenter.com/public/forms/KS-Grievance';
export const recommendedCare: ResourceFunction = ({ myUhcBaseUrl, id }) => {
  return `${myUhcBaseUrl}/communityplan/recommended-care${constructParams({ locale: id })}`;
};
export const medSuppMedicalSearch: IResource =
  'https://www.medicare.gov/forms-help-resources/find-compare-doctors-hospitals-other-providers';
export const dentistSearch: IResource = 'http://www.uhcmedicaredentistsearch.com';
export const dentistSearchV2: IResource = ({ connectUrl }) => `${connectUrl}/dentalProvider/root?showBackButton=true`;
export const visionSearch: IResource = 'https://www.myuhcvision.com/wf';
export const liveAndWorkWell: IResource = ({ language }) =>
  `https://provider.liveandworkwell.com/content/laww/providersearch/${language}/home.html?siteId=3659&lang=1`;
export const mentalHealthSearch: IResource = ({ connectUrl }) =>
  `${connectUrl}/behavioralProvider/root?showBackButton=true`;
export const optumPharmacy: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_PS&deepLink=RP`;
export const memberServicesPhone: IResource = ({ myUhcNoDuBaseUrl }) =>
  `${myUhcNoDuBaseUrl}/member/easyconnectpromptlayoutaem.do`;
export const coordinationBenefits: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/cobSummaryLayoutaem.do`;
export const eniNewCoordinationBenefits: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/activate/cob`;
export const uhcCommunityPlan: IResource = 'https://www.uhccommunityplan.com/';
export const revenueAdvantage: IResource = 'https://usapayx.com/';
export const exchangePremiumPayments: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_Cobra_HealthInsurancePremium&csId=15067`;
export const accessibility: IResource = 'https://www.uhc.com/legal/accessibility';
export const messages: IResource = {
  default: ({ myUhcBaseUrl, id }) => {
    return `${myUhcBaseUrl}/myuhc/messagecenter${constructParams({ locale: id })}`;
  },
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => {
    return `${myUhcBaseUrl}/medicare/help`;
  },
};
export const introVideo: IResource = ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}/member/onBoardingVideo.do`;
export const activateCard: IResource = () => 'activate/cards';
export const activateCardPreEffective: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/activate/cards`;

export const uCardHub: IResource = ({ populationUri }) => {
  const ucardBalances = FEATURE_FLAGS.ARCADE_FEATURES_UCARD_CTA_BALANCE_PAGE_ENABLED ? 'ucard/balances' : 'ucard';
  return populationUri ? `${populationUri}/${ucardBalances}` : ucardBalances;
};
export const uCardHubServicesOffers: IResource = ({ populationUri }) => {
  return populationUri ? `${populationUri}/ucard/services-offers` : 'ucard/services-offers';
};
export const claimsSpendingOverview: IResource = () => {
  return '/claims-and-accounts/spending-overview';
};
export const uCardHubHowToUse: IResource = ({ populationUri }) => {
  return populationUri ? `${populationUri}/ucard/how-to-use` : 'ucard/how-to-use';
};
export const flexCardHub: IResource = ({ populationUri }) => {
  return populationUri ? `${populationUri}/flex` : 'flex';
};
export const uCardRenewRewardsPreEffective: IResource = ({ myUhcBaseUrl }) => {
  return `${myUhcBaseUrl}/trackmyapp/rewards`;
};
export const uCardRenewRewards: IResource = ({ populationUri, lineOfBusiness }) => {
  switch (lineOfBusiness) {
    case LineOfBusiness.CS:
      return populationUri && FEATURE_FLAGS.ARCADE_FEATURES_SHOW_CS_REWARDS_URL
        ? `${populationUri}/rewards`
        : 'rewards';
    default:
      return populationUri ? `${populationUri}/rewards` : 'rewards';
  }
};
export const naviguardLink: IResource = 'https://naviguard.com/uhc-member/';
export const renewActiveLink: IResource = CONFIG.ARCADE_WEB_RENEW_ACTIVE_QUICK_LINK_URL;
export const viewMyPlans: IResource = ({ myUhcBaseUrl, id }) =>
  `${myUhcBaseUrl}/one-portal/plan-selector${constructParams({ locale: id })}`;

export const replaceUcard: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/ucard/order-materials`;

export const age65TransitionLearnMoreSsoLink: (
  shopperProfileImportConsent: boolean,
) => IResource = shopperProfileImportConsent => {
  return `${CONFIG.ARCADE_WEB_MR_NUDGE_LEARN_MORE_SSO_LINK}?ShopperProfileImportConsent=${shopperProfileImportConsent}`;
};
export const age65TransitionExploreSsoLink: (
  shopperProfileImportConsent: boolean,
) => IResource = shopperProfileImportConsent =>
  `${CONFIG.ARCADE_WEB_MR_NUDGE_EXPLORE_SSO_LINK}?ShopperProfileImportConsent=${shopperProfileImportConsent}`;

export const psxRouter =
  (originalPath: string): IResource =>
  ({ myUhcBaseUrl, id }) =>
    `${myUhcBaseUrl}/findcare?originalPath=${originalPath}&linkName="PrimaryCare"&locale=${id}`;

export const rallyRewardsV2Url: IResource = CONFIG.ARCADE_WEB_RALLY_REWARDS_V2_URL;
