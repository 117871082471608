export const trackFeatures = {
  accordion: 'accordion',
  accountDropdown: 'account-dropdown',
  accountInfo: 'account-info',
  accountSelector: 'account-selector',
  accountSummary: 'account-summary',
  accumulators: 'accumulators',
  activateBanner: 'activate-banner',
  activeReferrals: 'active-referrals',
  arcadeDashboardBanner: 'arcade-dashboard-banner',
  autoPayment: 'auto-payment',
  balances: 'balances',
  benefitsOverview: 'benefits-overview',
  callToChangePcp: 'call-to-change-pcp',
  careProviderInfo: 'care-provider-info',
  carousel: 'carousel',
  chatWithAgent: 'chat-with-agent',
  claim: 'claim',
  claims: 'claims',
  claimMarkedPaid: 'dhp-claim-marked-paid',
  communicationPreference: 'communication-preference',
  compareCare: 'compare-care',
  contactUs: 'contact-us',
  coronavirus: 'coronavirus',
  coronavirusHeading: 'coronavirus-heading',
  coronavirusSpotlight: 'coronavirus-spotlight',
  coronavirusLearnMore: 'coronavirus-learn-more',
  coronavirusCoping: 'coronavirus-coping',
  coronavirusPrompt: 'coronavirus-prompt',
  coverageStatusBanner: 'coverage-status-banner',
  contactChatSupport: 'contact-chat-support',
  contactNurse: 'contact-nurse-phoneNum',
  contactUsWeb: 'contact-us-web-assistance-resource',
  digitalIdCardModal: 'digital-id-card-modal',
  urgentCare: 'urgent-care',
  urgentCarePrompt: 'urgent-care-prompt',
  dynamicOnboardingPrompt: 'dynamic-onboarding-prompt',
  costInfo: 'cost-info',
  costs: 'common-services-costs',
  coverageInfo: 'coverage-info',
  customFaq: 'custom-faq',
  dashboard: 'dashboard',
  deductibleMessaging: 'deductible-messaging',
  dental: 'dental',
  deliveryPreference: 'delivery-preference',
  accountSummaryExplanation: 'account-summary-explanation',
  dentalDeductibleExplanation: 'dental-deductible-explanation',
  directDeposit: 'direct-deposit',
  drugTiers: 'drug-tiers',
  employerRewards: 'employer-rewards',
  eobError: 'eob-error',
  explorePrograms: 'explore-programs',
  faq: 'faq',
  financialAccounts: 'financial-accounts',
  findCare: 'find-care',
  findADoctor: 'find-a-doctor',
  flexCard: 'flex-card',
  flexCardBanner: 'flex-card-banner',
  footer: 'footer',
  guidedSearch: 'guided-search',
  headerDesktop: 'header-desktop',
  headerMobile: 'header-mobile',
  healthResources: 'health-resources',
  help: 'help',
  helpPageBack: 'help-page-back',
  helpFaq: 'help-faq',
  impersonation: 'impersonation-banner',
  inactiveModal: 'inactive-modal',
  incentives: 'incentives',
  internalError: 'internal-error',
  localeSelector: 'locale-selector',
  markAsPaid: 'mark-as-paid',
  mainNav: 'main-nav',
  medical: 'medical',
  medicareEligibilityElementName: 'url-link-tell me more you are eligible for medicare',
  medicareEligibilitySection: 'you-are-now-eligible-for-medicare-banner',
  memberIdCards: 'member-id-cards',
  memberIdInfo: 'member-id-info',
  memberIdSelector: 'member-id-selector',
  memberResources: 'member-resources',
  mobileHalfModal: 'mobile-half-modal',
  modal: 'modal',
  modalClose: 'modal-close',
  notification: 'notification',
  nav: 'nav',
  networkSelector: 'network-selector',
  onboardingVideo: 'onboarding-video',
  paperlessBanner: 'paperless-banner',
  idCardspaperlessPreferences: 'id-cards-preferences',
  idCardspaperlessPreferencesDeliveryPreference: 'delivery-preference',
  idCardspaperlessPreferenceSave: 'preference-info',
  planBalancesBanner: 'plan-balances-banner',
  planDetails: 'plan-details',
  payNow: 'pay-now-modal',
  pcpAndSaved: 'pcp-and-saved',
  pcpChange: 'pcp-change',
  pcpChangeUnavailable: 'pcp-change-unavailable',
  pcpReferrals: 'pcp-referrals',
  pharmacy: 'pharmacy',
  planBalances: 'plan-balances',
  planReferenceList: 'plan-reference-list',
  preferredMail: 'preferred-mail-service-pharmacy',
  preferredRetail: 'preferred-retail-pharmacy',
  premiumPayments: 'premium-payments',
  prescriptionsInfo: 'prescriptions-info',
  programsInfo: 'programs-info',
  promoPanels: 'promo-panels',
  promoTiles: 'promo-tiles',
  promos: 'promos',
  providerReferrals: 'provider-referrals',
  recommendations: 'recommendations',
  recommendationsHeader: 'recommendations-header',
  recommendationsActive: 'recommendations-active',
  recommendationsDismissed: 'recommendations-dismissed',
  recommendationsPageBack: 'recommendations-page-back',
  requestFailure: 'request-failure',
  resources: 'resources',
  rewards: 'rewards',
  rolodex: 'rolodex',
  seeYouLater: 'see-you-later',
  selectDay: 'select-day',
  selectMonth: 'select-month',
  selectYear: 'select-year',
  spendingAndCostSummary: 'spending-and-cost-summary',
  standardRetail: 'standard-retail-pharmacy',
  statements: 'statements',
  subNav: 'sub-nav',
  successSection: 'success-section',
  summary: 'summary',
  unauthorizedError: 'unauthorized-error',
  utilityNav: 'utility-nav',
  vaccinePrompt: 'vaccine-prompt',
  viewClaim: 'view-claim',
  vision: 'vision',
  waysToSave: 'ways-to-save',
  wellness: 'wellness',
  yourPcp: 'your-pcp',
  back: 'back',
  notNowCheck: 'not-now-check',
  notNowCheckBox: 'not-now-checkbox',
  optumVirtualCare: 'optumvirtualcare',
  uCard: 'ucard',
  videoBanner: 'video-banner',
  videoBannerV2: 'video-banner-v2',
  uhcRewards: 'uhc-rewards-info',
  useClaimsData: 'use-claims-data',
  rallyRewards: 'rally-rewards-info',
};
